<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image loadup-bg"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12" md="12" sm="6" cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">LoadUp</h2>
              <p>
                
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          
          <v-col lg="12" md="12" sm="12" cols="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="6" md="12" cols="12">
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="items"
                        :index="index"
                        @close="index = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in items"
                          :key="imageIndex"
                        >
                          <img class="w-100" src="../../assets/images/product/loadup01.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="12" sm="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title">
                        <span class="subtitle">Our service</span>
                        <h2 class="heading-title">One Click 텍스트분석</h2>
                        <p class="description">
                          LoapUp은 전문가가 아니어도 텍스트분석을 쉽게 할 수 있습니다!<br />
                          복잡한 분석 방법을 요구하지 않고, 단순히 키워드를 입력하여 다양한 데이터 소스에서 중요한 인사이트를 추출할 수 있습니다. <br />
                          뉴스, 트위터 데이터 등 다양한 소스를 지원하며, 워드클라우드, 막대 차트, 연관성 차트 등의 시각화 도구를 통해 데이터를 명확하게 이해할 수 있도록 도와줍니다. <br />
                          또한 보유하고 있는 파일도 개인화된 텍스트 분석 결과를 제공합니다.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-row>
                    <v-col lg="12" md="12" sm="12" cols="12">
                      <div class="text-center view-more-btn mt--60 mt_sm--30">
                        <a class="btn-default" href="http://loadup.kr" target="_blank">Go LoadUp</a>
                      </div>
                    </v-col>
                  </v-row>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>


          <!-- <v-col lg="12" md="12" sm="12" cols="12">
            <div class="service-details-inner mt--50">
              <div class="inner">
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="12" md="12" sm="12" cols="12">
                    <div class="details mt_md--30">
                      <div class="section-title">
                        <h4 class="heading-title">사전 관리 모듈 (기본 사전, 개체명 인식 사전)</h4>
                        <p class="description">
                          텍스트 분석에 필수적인 사전 관리를 조직 단위로 편리하게 하도록 개발된 기능합니다.<br /> 
                          사용자별 사전 입력을 모니터링 가능 개체명사전, 사용자사전, 불용어사전, 유의어사전 관리 기능합니다.
                        </p>
                        <h4 class="heading-title">텍스트 분석 엔진 (Donto)</h4>
                        <p class="description">
                          자사 개발 텍스트 분석 엔진, Donto를 REST API로 호출하여 사용합니다.<br /> 
                          다양한 방법으로 입력된 텍스트를 분석하여 결과를 화면 또는 파일로 다운로드. 데이터 분석가의 텍스트 전처리 과정을 간편하게 합니다.
                        </p>
                          <h4 class="heading-title">시각화 모듈</h4>
                        <p class="description">
                          분석된 키워드를 간단한 차트, 워드클라우드 또는 키워드 네트워크 차트 등을 제공합니다.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="6" cols="12">
                    <div class="thumb position-relative">
                      <div class="thumbnail position-relative">
                        <img class="w-100" src="../../assets/images/product/loadup02.png" alt="" />
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>

          <v-col lg="12">
            <div class="service-details-inner mt--50">
              <div class="inner">
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30">
                      <div class="section-title">
                        <h4 class="heading-title">로드업 프로세스맵</h4>
                        <p class="description">
                          데이터 수집, 전처리, 분석, 시각화 과정을 클릭 몇 번 만으로 처리 할 수 있습니다. 디랩의 축적된 데이터 처리 기술은 일반 사용자 뿐만 아니라 기업 요구사항에도 적용할 수 있습니다.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12">
                    <div class="thumb position-relative">
                      <div class="thumbnail position-relative">
                        <img class="w-100" src="../../assets/images/product/loadup03.png" alt="" />
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col> -->



        </v-row>
      </v-container>
    </div>

    <div class="rn-service-details ptb--120 bg_color--5" style="display:none;">
      <v-container>
        <!-- Start Single Content  -->
        <v-row class="sercice-details-content align-center row--35">
          <v-col lg="6" md="6" cols="12" order="2" order-md="1">
            <div class="details mt_md--30 mt_sm--30">
              <div class="section-title">
                <h2 class="heading-title">메인추천</h2>
                <p class="description">
                  사용자의 관심데이터와 트렌드를 바탕으로 메인화면을 통해 다양한 기업을 추천해 줍니다.
                </p>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="2" cols="12" order="1" order-md="2">
            <div class="thumb position-relative">
              <img
                class="w-100" src="../../assets/images/service/service-02.png"
                alt="Service Images"
              />
            </div>
          </v-col>

          
          
        </v-row>
        <!-- End Single Content  -->
      </v-container>
    </div>
    
    <Footer />
  </div>
</template>

<script>
  import feather from "feather-icons";
  import Header from "../../components/header/Header";
  import Footer from "../../components/footer/Footer";
  import PricingPlan from "../../components/pricing-plan/PricingPlan";
  export default {
    components: {
      Header,
      Footer,
      PricingPlan,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/product/dstocks_site.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
          {
            id: 5,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 6,
            icon: "check",
            desc: `Fast-Track Your business`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
